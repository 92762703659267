$(document).ready(function(){
    /******************* M A S O N R Y **********************/
    function masonryInit(){
        window.updateMasonryAjaxLoad = function(){
        $(".mas_img").unbind('load');
        $(".mas_img").bind('load',function(){
            TweenMax.to($(this).parent().parent(), 0.6, {opacity:1, ease:Sine.easeOut})
            msnry.reloadItems();
            msnry.layout();
        })
        msnry.reloadItems();
        msnry.layout();
        }

        var container = document.querySelector('.grid_hld');
        window.msnry = new Masonry( container, {
            itemSelector: '.grid-item',
            percentPosition: true,
            transitionDuration: '0.6s',
            stamp: '.stamp'
        });
        setTimeout(function(){
            msnry.layout();
        },10);    
        updateMasonryAjaxLoad();
    }
    
    
    if(winWT >= 1024){
        masonryInit();
    }
    /*******************M A S O N R Y **********************/

    $(window).on('load', function(){
        TweenMax.to('.stamp',0.2,{opacity:1, ease:Sine.easeOut});
    });

});